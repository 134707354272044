<template>
  <div class="card p-shadow-6">
    <h1>Relatórios das Instalações</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Divider />
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 p-md-2">
        <Button
          label="Ver Instalações sem relatório"
          class="p-button-success p-button-outlined"
          @click="goToInstallationWitchoutReports"
        />
      </div>
    </div>
    <Divider />
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <DataTable
      :value="installationsList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="20"
      class="p-datatable-sm"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} instalações"
      sortField="name"
      :sortOrder="1"
      :autoLayout="true"
      responsiveLayout="scroll"
    >
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column
        field="id"
        header="Id"
        sortable
        :headerStyle="{ width: '170px' }"
        filterField="id"
      >
        <template #body="slotProps">
          <span class="p-column-title">Id:</span>
          {{ slotProps.data.id }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="name" header="Nome" sortable filterField="name">
        <template #body="slotProps">
          <span class="p-column-title">Nome:</span>
          {{ slotProps.data.name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="description"
        header="Descrição"
        sortable
        filterField="description"
      >
        <template #body="slotProps">
          <span class="p-column-title">Descrição:</span>
          {{ slotProps.data.description }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="seller"
        header="Vendedor"
        sortable
        filterField="seller"
      >
        <template #body="slotProps">
          <span class="p-column-title">Vendedor:</span>
          {{ slotProps.data.seller }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="manager"
        header="Gestor"
        sortable
        filterField="manager"
      >
        <template #body="slotProps">
          <span class="p-column-title">Gestor:</span>
          {{ slotProps.data.manager }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="responsible_technical"
        header="Responsável"
        sortable
        filterField="responsible_technical"
      >
        <template #body="slotProps">
          <span class="p-column-title">Responsável:</span>
          {{ slotProps.data.responsible_technical }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="technician"
        header="Técnico Res."
        sortable
        filterField="technician"
      >
        <template #body="slotProps">
          {{ slotProps.data.technician }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="has_installation_sheet"
        header="Folha Instalação"
        sortable
        filterField="has_installation_sheet"
        dataType="boolean"
      >
        <template #body="slotProps">
          <span class="p-column-title">Folha Instalação:</span>
          {{ slotProps.data.has_installation_sheet == true ? "Sim" : "Não" }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="[
              { value: false, label: 'Não' },
              { value: true, label: 'Sim' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>

      <Column :headerStyle="{ width: '140px' }">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Ver comentários'"
            icon="pi pi-comments"
            class="p-button-sm p-button-rounded p-button-secondary p-button-outlined p-mr-1"
            @click="viewComments(slotProps.data.id)"
          />
          <Button
            v-if="slotProps.data.report_url"
            v-tooltip.left="'Ver Relatório'"
            icon="pi pi-eye"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="goReport(slotProps.data.report_url)"
          />
          <Button
            v-else
            v-tooltip.left="'Link Inválido'"
            icon="pi pi-eye"
            class="p-button-rounded p-button-danger p-button-outlined p-mr-2"
            @click="invalidLinkReport()"
          />

          <Button
            v-if="slotProps.data.has_installation_sheet"
            v-tooltip.left="'Ir para folha de instalação'"
            icon="pi pi-book"
            class="p-button-rounded p-button-info p-button-outlined p-ml-1"
            @click="
              $router.push(`/task-installation-sheet/${slotProps.data.id}/1`)
            "
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import installationsService from "../services/installations.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "InstallationsWitchReports",
  data() {
    return {
      loading: true,
      installationsList: [],
      filters: {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        seller: { value: null, matchMode: FilterMatchMode.CONTAINS },
        manager: { value: null, matchMode: FilterMatchMode.CONTAINS },
        technician: { value: null, matchMode: FilterMatchMode.CONTAINS },
        responsible_technical: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        has_installation_sheet: {
          value: null,
          matchMode: FilterMatchMode.EQUALS,
        },
      },
    };
  },
  created() {
    this.loading = true;
    this.getInstallations();
  },
  methods: {
    getInstallations() {
      this.loading = true;
      return installationsService
        .getInstallationWitchReport()
        .then((response) => {
          this.loading = false;
          return (this.installationsList = response);
        });
    },
    goToInstallationWitchoutReports() {
      this.$router.push(`/installations-witchout-report`);
    },
    goReport(url) {
      window.open(url, "_blank");
    },
    invalidLinkReport() {
      this.$toast.add({
        severity: "error",
        summary: "Link Iválido",
        detail: "O link do relatório não é válido",
        life: 3000,
      });
    },
    viewComments(installationsId) {
      let route = this.$router.resolve({
        path: `/project-comments-view/${installationsId}`,
      });
      window.open(route.href, "_blank");
    }
  },
};
</script>
<style scoped lang="scss">
#year-selector {
  width: 60px;
}
.p-column-filter {
  width: 100%;
}
</style>
